<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("ranking_reward.head") }}</h2>
    </v-col>
    <v-col cols="12">

      <v-card>
        <v-card-title v-if="canCreate"> {{ $t("ranking_reward.head_list") }} </v-card-title>
        <v-form v-if="canCreate" ref="form" class="d-flex">
          <v-card-actions class="pb-0 d-flex align-start">
            <!-- <v-row no-gutters> -->
            <span class="mr-2" style="display: flex;">
              <div
                style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
                {{ $t('return_balance.type') }} </div>
              <v-select :items="gametype" item-text="label" v-model="gameTypeSelect" outlined dense
                style="max-width: 110px;" />
            </span>
            <span class="mr-2" style="display: flex;">
              <div
                style="width: 98px; height: 38px; text-agent: center; background: #e5e5e5; border-radius: 8px; color: #737076; display: flex; justify-content: center; align-items: center;">
                {{ $t('return_balance.type') }} </div>
              <v-select :items="competitiontype" item-text="label" v-model="competitionTypeSelect" outlined dense
                style="max-width: 110px;" />
            </span>

            <v-btn color="primary" class="" @click="getRankingReward"> {{ $t('ranking_reward.search') }} </v-btn>
            <!-- </v-row> -->
          </v-card-actions>
          <v-spacer />
          <span style='padding-right: 50px'><v-btn color="primary" class="" @click="linkToAdd"> +{{
            $t('ranking_reward.create') }} </v-btn></span>
        </v-form>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn v-if="!item.isCleared" color="error" class="white--text text-capitalize"
                @click="deleteRankingReward(item)"> {{ $t('ranking_reward.delete') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'
import rankingReward from '@/store/rankingReward'
export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      search: '',
      loading: false,
      canCreate: false,
      headers: [
        {
          text: this.$t('ranking_reward.header1'),
          value: 'order',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ranking_reward.header2'),
          value: 'rewardType',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ranking_reward.header3'),
          value: 'rewardAmount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ranking_reward.header4'),
          value: 'gameType',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('ranking_reward.header5'),
          value: 'competitionType',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      gametype: ["casino", "slot", "sport", "money", "all"],
      competitiontype: ["turnover", "deposit", "withdraw"],
      gameTypeSelect: "slot",
      competitionTypeSelect: "deposit",
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          const result = await this.getRankingReward()
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'ranking-reward',
            url: window.location.href,
            detail: 'ระบบจัดการ ranking reward',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'rankingReward')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [...this.headers,
        {
          text: this.$t('ranking_reward.manage'),
          value: 'action',
          width: '95px',
          align: 'center',
          sortable: false,
        }
        ]
      }

    },
    async getRankingReward() {
      try {
        this.loading = true
        let resData = await this.$store.dispatch('getRankingReward', `gametype=${this.gameTypeSelect}&competitiontype=${this.competitionTypeSelect}`)
        this.pagination.totalItems = resData.data.length
        this.value = resData.data
        this.loading = false
      } catch (e) { console.log('error ' + e) }
    },
    linkToAdd() {
      this.$router.push({ name: 'rankingreward-add' })
    },
    async addCouponData() {
      try {
        const couponName = this.couponForm?.name.trim()
        const data = {
          name: (this.couponForm?.name && this.couponForm?.name !== "") ? this.couponForm.name : `${this.couponForm.set}_${this.couponForm.credit}_${this.couponForm.turn_over}_${this.couponForm.time_use}_${this.couponForm.time_expire}`,
          credit: parseInt(this.couponForm.credit),
          turn_over: parseInt(this.couponForm.turn_over),
          time_use: this.couponForm.time_use,
          time_expire: this.couponForm.time_expire,
          count: parseInt(this.couponForm.count), // จำนวนยูสที่ใช้ได้ ต่อ คูปอง
          set: parseInt(this.couponForm.set), // จำนวนคูปอง ต่อ ชุด
          max: parseInt(this.couponForm.max_use),//จำนวนสูงสุดที่ใช้ได้ ต่อ คูปอง
        }

        await this.$store.dispatch('addCoupon', data)
        this.loading = true
        const result = await this.getRankingReward()
        this.value = result.data
        this.$refs.form.reset()
        this.couponForm = {
          credit: '',
          turn_over: '',
          time_use: moment().format('YY-MM-DD'),
          time_expire: moment().add(90, 'days').format('YY-MM-DD'),
          count: '1',
          set: '',
        }
        this.loading = false
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    async deleteRankingReward(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('ranking_reward.clear_confirm')} ?`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteRankingReward', item.id)
            this.$swal.fire(this.$t('ranking_reward.clear_successful'), '', 'success')
            const result = await this.getRankingReward()
            // this.pagination.totalItems = result.count
            // this.value = result.data
            // this.loading = false
          }
        })
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
